import React, { useState, useRef } from 'react'
import ItemsPedido from './ItemsPedido'
import BackendService from '../../integrations/BackendService'
import Swal from 'sweetalert2';

export default function DetalhesPedido({ pedido }) {
    const backendService = new BackendService();
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [cancelModalOpen, setCancelModalOpen] = useState(false);
    const [despacharModalOpen, setDespacharModalOpen] = useState(false);
    const [finalizarModalOpen, setFinalizarModalOpen] = useState(false);
    const [selectedMotivo, setSelectedMotivo] = useState('');
    const [customMotivo, setCustomMotivo] = useState('');

    const openConfirmModal = () => setConfirmModalOpen(true);
    const closeConfirmModal = () => setConfirmModalOpen(false);

    const openCancelModal = () => setCancelModalOpen(true);
    const closeCancelModal = () => setCancelModalOpen(false);

    const openDespacharModal = () => setDespacharModalOpen(true);
    const closeDespacharModal = () => setDespacharModalOpen(false);

    const openFinalizarModal = () => setFinalizarModalOpen(true);
    const closeFinalizarModal = () => setFinalizarModalOpen(false);
    const componentRef = useRef();
    const componentReff = useRef();

    const handleCancel = async () => {
        try {
            const motivoFinal = selectedMotivo === 'custom' ? customMotivo : selectedMotivo;
            if (!motivoFinal) {
                // Se não, exibe uma mensagem ou simplesmente retorna
                Swal.fire({
                    icon: 'info',
                    title: 'Ops!',
                    text: 'Você deve informar um motivo para cancelar o pedido.',
                    confirmButtonText: 'Ok',
                });
                return;
            }
            const cancelarPedidoDTO = {
                motivoCancelamento: motivoFinal,
            };
            await backendService.cancelarPedido(pedido.id, cancelarPedidoDTO);
            window.location.reload();
        } catch (error) {
            console.error(error.message);
        }
        closeCancelModal();
        setSelectedMotivo('');
        setCustomMotivo('');
    };

    const handleConfirm = async () => {
        try {
            await backendService.confirmarPedido(pedido.id);
            await imprimirAndRefresh()
        } catch (error) {
            console.error(error.message);
        }
        closeConfirmModal();
    };

    const handleDespachar = async () => {
        try {
            await backendService.despacharPedido(pedido.id);
            window.location.reload();
        } catch (error) {
            console.error(error.message);
        }
        closeConfirmModal();
    };

    const handleFinalizar = async () => {
        try {
            await backendService.finalizarPedido(pedido.id);
            window.location.reload();
        } catch (error) {
            console.error(error.message);
        }
        closeConfirmModal();
    };

    const imprimirAndRefresh = async () => {
        handlePrintNative(true);
    }

    const imprimir = async () => {
        await new Promise((resolve) => {
            setTimeout(() => {
                handlePrintNative(false);
                resolve();
            }, 200); // Delay de 1 segundo
        });
    }

    const dataHoraPedido = new Date(pedido?.dataHoraPedido);

    // Extrair e formatar a data
    const dia = dataHoraPedido.getDate().toString().padStart(2, '0');
    const mes = (dataHoraPedido.getMonth() + 1).toString().padStart(2, '0'); // getMonth() retorna 0-11
    const ano = dataHoraPedido.getFullYear();
    const dataFormatada = `${dia}/${mes}/${ano}`;

    // Extrair e formatar a hora
    const horas = dataHoraPedido.getHours().toString().padStart(2, '0');
    const minutos = dataHoraPedido.getMinutes().toString().padStart(2, '0');
    const horaFormatada = `${horas}:${minutos}`;

    const handlePrintNative = (refresh) => {
        const printContent = componentReff.current;
        const windowPrint = window.open('', '', 'width=800,height=600');
        const windowPrintDocument = windowPrint.document;

        windowPrintDocument.write(`
            <html>
              <head>
                <title>Imprimir Pedido</title>
                <style>
                  body {
                    font-family: Arial, sans-serif;
                  }
                  /* Estilo para ocultar o botão durante a impressão */
                  #printButton {
                    display: block; /* Mostra o botão na visualização normal */
                    margin: 20px auto;
                    padding: 10px;
                    font-size: 16px;
                  }
                  @media print {
                    #printButton {
                      display: none; /* Oculta o botão na impressão */
                    }
                  }
                </style>
              </head>
              <body>
                 
                  <button id="printButton">
                      Imprimir
                  </button>
                   ${printContent.innerHTML}
                  <script>
                      document.getElementById('printButton').onclick = function() {
                          window.print();
                      };
                  </script>
              </body>
            </html>
        `);
        windowPrintDocument.close();

        // Aguarde 2 segundos antes de imprimir
        setTimeout(() => {
            windowPrint.focus(); // Foca na janela de impressão
            // windowPrint.print(); // Chama a função de impressão

            // // Feche a janela após imprimir
            // windowPrint.close();

            // Atualiza a página se necessário
            if (refresh) {
                window.location.reload();
            }
        }, 2000);
    };

    //     const handlePrintNative = (refresh) => {
    //         const windowPrint = window.open('', '', 'width=800,height=600');
    //         const windowPrintDocument = windowPrint.document;

    //         //         windowPrintDocument.write(`
    //         //             <!DOCTYPE html>
    //         // <html lang="pt-BR">
    //         // <head>
    //         //     <meta charset="UTF-8">
    //         //     <meta name="viewport" content="width=device-width, initial-scale=1.0">
    //         //     <title>Imprimir Pedido</title>
    //         // </head>
    //         // <body >

    //         //     <div style="text-align: center;">
    //         //         <h3 style="margin: 0;">PARA ENTREGA</h3>
    //         //         <p style="margin: 5px 0;">${dataFormatada} - ${horaFormatada}</p>
    //         //         <p style="margin: 5px 0;">${pedido.nomeRestaurante}</p>
    //         //         <h1 style="margin: 10px 0;">Pedido ${pedido.id}</h1>
    //         //     </div>

    //         //         <div style="margin-bottom: 20px;">
    //         //             <h2 style="margin: 0;">Itens:</h2>
    //         //             ${pedido.itensPedido.map(item => `
    //         //                 <div style=" border-bottom: 1px dashed #000;">
    //         //                     <div style="display: flex; justify-content: space-between; margin: 5px 0;">
    //         //                         <div>
    //         //                             <span>${item.quantidade}x ${item.nome}</span>
    //         //                         </div>
    //         //                         <span> R$ ${item.precoTotal.toFixed(2)}</span>
    //         //                     </div>
    //         //                     <div style="">
    //         //                         ${item.adicional ? `
    //         //                             <div style="margin: 5px 0; font-size: 0.9em;">
    //         //                                 <p style="margin: 0;">Adicionais:</p>
    //         //                                 ${item.adicional.split(',').map(adicional => `
    //         //                                     <p style="margin: 0;">${adicional.trim()}</p>
    //         //                                 `).join('')}
    //         //                             </div>
    //         //                         ` : ''}
    //         //                         ${item.observacao ? `<p style="margin: 5px 0; font-size: 0.9em;">Observação: ${item.observacao}</p>` : ''}
    //         //                     </div>
    //         //                 </div>
    //         //             `).join('')}
    //         //         </div>

    //         //     <div style="margin-bottom: 20px;">
    //         //         <h2 style="margin: 0;">Cliente:</h2>
    //         //         <p style="margin: 5px 0;">Nome: ${pedido.nomeCliente}</p>
    //         //         <p style="margin: 5px 0;">Telefone: ${pedido.telefoneCliente}</p>
    //         //         <p style="margin: 5px 0;">Entrega: ${pedido.endereco.logradouro}, ${pedido.endereco.numero}, ${pedido.endereco.bairro} - ${pedido.endereco.complemento} </p>
    //         //     </div>

    //         //     <div text-align: left;">
    //         //         <h2 style="margin: 0;">Pagamento:</h2>
    //         //         <p style="margin: 5px 0;">Forma de Pagamento: ${pedido.formaPagamento}</p>
    //         //         <p style="margin: 5px 0;">Subtotal: R$ ${pedido.itensPedido.reduce((acc, item) => acc + item.precoTotal, 0).toFixed(2)}</p>
    //         //         <p style="margin: 5px 0;">Taxa de entrega: R$ ${pedido.valorFrete.toFixed(2)}</p>
    //         //         <p style="margin: 5px 0;">Total: R$ ${pedido.valorTotal.toFixed(2)}</p>
    //         //         ${pedido?.trocoPara && pedido.trocoPara !== 0 ? `
    //         //             <p style="margin: 5px 0;">Troco para: R$ ${pedido.trocoPara.toFixed(2)}</p>
    //         //             ` : ''}
    //         //     </div>

    //         // </body>
    //         // </html> `);
    //         const printContent = componentReff.current;
    //         windowPrintDocument.write(`
    //     <html>
    //       <head>
    //         <title>Imprimir Pedido</title>
    //       </head>
    //       <body>
    //           ${printContent.innerHTML}
    //       </body>
    //     </html>
    //   `);

    //         windowPrintDocument.close();
    //         setTimeout(() => {
    //             if (refresh) {
    //                 window.location.reload();
    //             }
    //             windowPrint.close()
    //         }, 200)
    //         windowPrint.focus();
    //         windowPrint.print();

    //     };

    if (!pedido) {
        return (
            <div className='flex items-center justify-center min-h-[30rem] max-h-[50rem] w-full p-4'>
                <span>Por favor, selecione um pedido para ver os detalhes.</span>
            </div>
        );
    }

    return (
        <div className='flex flex-col w-full '>
            <div className='flex flex-col w-full h-full p-2 bg-white rounded-lg shadow-lg'>
                <div ref={componentReff} style={{ display: 'none' }}>
                    <div style={{ textAlign: 'center' }}>
                        <h3 style={{ margin: 0 }}>{pedido.tipoDeEncomenda === 'DELIVERY' ? 'PARA ENTREGA' : 'PARA RETIRADA'}</h3>
                        <p style={{ margin: '5px 0' }}>{dataFormatada} - {horaFormatada}</p>
                        <p style={{ margin: '5px 0' }}>{pedido.nomeRestaurante}</p>
                        <h1 style={{ margin: '10px 0' }}>Pedido {pedido.id}</h1>
                    </div>

                    <div style={{ marginBottom: '20px' }}>
                        <h2 style={{ margin: 0 }}>Itens:</h2>
                        {pedido.itensPedido.map(item => (
                            <div key={item.id} style={{ borderBottom: '1px dashed #000' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', margin: '5px 0' }}>
                                    <div>
                                        <span>{item.quantidade}x {item.nome}</span>
                                    </div>
                                    <span>R$ {item.precoTotal.toFixed(2)}</span>
                                </div>
                                {item.adicional && (
                                    <div style={{ margin: '5px 0', fontSize: '0.9em' }}>
                                        <p style={{ margin: 0 }}>Adicionais:</p>
                                        {item.adicional.split(',').map((adicional, index) => (
                                            <p key={index} style={{ margin: 0 }}>{adicional.trim()}</p>
                                        ))}
                                    </div>
                                )}
                                {item.observacao && (
                                    <p style={{ margin: '5px 0', fontSize: '0.9em' }}>Observação: {item.observacao}</p>
                                )}
                            </div>
                        ))}
                    </div>

                    <div style={{ marginBottom: '20px' }}>
                        <h2 style={{ margin: 0 }}>Cliente:</h2>
                        <p style={{ margin: '5px 0' }}>Nome: {pedido.nomeCliente}</p>
                        <p style={{ margin: '5px 0' }}>Telefone: {pedido.telefoneCliente}</p>
                        <p style={{ margin: '5px 0' }}>Entrega: {pedido.endereco.logradouro}, {pedido.endereco.numero}, {pedido.endereco.bairro} - {pedido.endereco.complemento}</p>
                    </div>

                    <div style={{ textAlign: 'left' }}>
                        <h2 style={{ margin: 0 }}>Pagamento:</h2>
                        <p style={{ margin: '5px 0' }}>Forma de Pagamento: {pedido.formaPagamento}</p>
                        <p style={{ margin: '5px 0' }}>Subtotal: R$ {pedido.itensPedido.reduce((acc, item) => acc + item.precoTotal, 0).toFixed(2)}</p>
                        <p style={{ margin: '5px 0' }}>Taxa de entrega: R$ {pedido.valorFrete.toFixed(2)}</p>
                        <p style={{ margin: '5px 0' }}>Total: R$ {pedido.valorTotal.toFixed(2)}</p>
                        {pedido?.trocoPara && pedido.trocoPara !== 0 && (
                            <p style={{ margin: '5px 0' }}>Troco para: R$ {pedido.trocoPara.toFixed(2)}</p>
                        )}
                    </div>
                </div>
                <div ref={componentRef} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', padding: '16px' }}>
                        <span style={{ fontWeight: '600', fontSize: '1.125rem' }}>Pedido #{pedido.id} - Realizado {dataFormatada} às {horaFormatada}</span>
                        <span>Endereço: {pedido.endereco.logradouro}, {pedido.endereco.numero}, {pedido.endereco.bairro} - {pedido.endereco.complemento} - CEP {pedido.endereco.cep} - {pedido.endereco.cidade}</span>
                        <span>
                            Cliente: {pedido.nomeCliente},
                            <a
                                href={`https://wa.me/55${pedido.telefoneCliente.replace(/\D/g, '')}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: 'blue', textDecoration: 'underline' }}
                            >
                                {pedido.telefoneCliente}
                            </a>
                        </span>
                        <span>Pedido para <span className='font-bold'>{pedido.tipoDeEncomenda.toLowerCase()}</span></span>
                    </div>
                    <ItemsPedido pedido={pedido} />
                    <div style={{ display: 'flex', flexDirection: 'column', padding: '16px' }}>
                        <span style={{ marginLeft: 'auto', marginTop: '8px' }}>
                            Total Itens: <span style={{ fontWeight: '600' }}>R$ {pedido.itensPedido.reduce((acc, item) => acc + item.precoTotal, 0).toFixed(2)}</span>
                        </span>
                        {pedido.cupom && (
                            <>
                                {pedido.cupom.tipoDesconto === 'VALOR' ? (
                                    <span style={{ marginLeft: 'auto', color: '#16A34A', fontWeight: '600' }}>
                                        Cupom R$ {pedido.cupom.desconto.toFixed(2)}
                                    </span>
                                ) : (
                                    <span style={{ marginLeft: 'auto', color: '#16A34A', fontWeight: '600' }}>
                                        Cupom {pedido.cupom.desconto.toFixed(2)}%
                                    </span>
                                )}
                            </>
                        )}
                        <span style={{ marginLeft: 'auto' }}>Frete: <span style={{ fontWeight: '600' }}>R$ {pedido.valorFrete.toFixed(2)}</span></span>
                        <span style={{ marginLeft: 'auto' }}>Total: <span style={{ fontWeight: '600' }}>R$ {pedido.valorTotal.toFixed(2)}</span></span>
                        <span style={{ marginLeft: 'auto' }}>Forma de Pagamento: <b>{pedido.formaPagamento}</b></span>
                        {pedido.trocoPara !== undefined && pedido.trocoPara !== null && pedido.trocoPara !== 0 && (
                            <span style={{ marginLeft: 'auto', marginBottom: '8px' }}>Troco para: R$ {pedido.trocoPara.toFixed(2)}</span>
                        )}
                    </div>
                </div>
                <div className='flex w-full justify-between'>
                    <div>
                        <button className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded mr-2"
                            onClick={openCancelModal}>
                            Cancelar
                        </button>
                        {pedido.statusPedido === "DESPACHADO" || pedido.statusPedido === "AGUARDANDO_RETIRADA" ? (
                            <button className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                                onClick={openFinalizarModal}>
                                Finalizar
                            </button>
                        ) : pedido.statusPedido === "AGUARDANDO_CONFIRMACAO" ? (
                            <button className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                                onClick={openConfirmModal}>
                                Confirmar
                            </button>
                        ) : (
                            <button className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                                onClick={openDespacharModal}>
                                {pedido.tipoDeEncomenda === "DELIVERY" ? 'Despachar' : 'Finalizar'}
                            </button>
                        )}
                    </div>
                    {pedido.statusPedido !== "AGUARDANDO_CONFIRMACAO" && (
                        <div>
                            <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                                onClick={imprimir}>
                                Imprimir Pedido
                            </button>
                        </div>
                    )}
                </div>
            </div>


            {/* Modal de cancelamento */}
            {
                cancelModalOpen && (
                    <div className="fixed z-10 inset-0 overflow-y-auto">
                        <div className="flex items-center justify-center min-h-screen">
                            <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
                            <div className="modal-container bg-white mx-auto rounded shadow-lg z-50 w-4/12">
                                <div className="flex flex-col items-center py-4 px-6 w-full">
                                    <p className='text-lg font-bold'>Deseja realmente cancelar o pedido?</p>
                                    <div className="flex flex-col mt-4 w-full">
                                        <label htmlFor="motivo" className="block mb-2 text-sm font-medium text-gray-700">
                                            Motivo do cancelamento:
                                        </label>
                                        <select
                                            id="motivo"
                                            value={selectedMotivo}
                                            onChange={(e) => setSelectedMotivo(e.target.value)}
                                            className="border border-gray-300 rounded py-2 px-3 mb-4"
                                        >
                                            <option value="">Selecione um motivo</option>
                                            <option value="Cliente desistiu da compra">Cliente desistiu da compra</option>
                                            <option value="Não temos todos os itens da sua compra">Não temos todos os itens da sua compra</option>
                                            <option value="custom">Outro motivo (especificar)</option>
                                        </select>

                                        {selectedMotivo === "custom" && (
                                            <textarea
                                                placeholder="Escreva seu motivo aqui..."
                                                value={customMotivo}
                                                onChange={(e) => setCustomMotivo(e.target.value)}
                                                className="border border-gray-300 rounded py-2 px-3 mb-4 w-full"
                                                rows={3}
                                            />
                                        )}
                                    </div>
                                    <div className="mt-4">
                                        <button onClick={handleCancel} className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded">
                                            Sim
                                        </button>
                                        <button onClick={closeCancelModal} className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded ml-2">
                                            Não
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            {/* Modal de confirmação */}
            {
                confirmModalOpen && (
                    <div className="fixed z-10 inset-0 overflow-y-auto">
                        <div className="flex items-center justify-center min-h-screen">
                            <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
                            <div className="modal-container bg-white mx-auto rounded shadow-lg z-50">
                                <div className=" flex flex-col items-center  py-4 px-6">
                                    <p>Deseja realmente confirmar o pedido?</p>
                                    <div className="mt-4">
                                        <button onClick={handleConfirm} className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded">
                                            Sim
                                        </button>
                                        <button onClick={closeConfirmModal} className="bg-red-500 hover:bg-red-600  text-white font-bold py-2 px-4 rounded ml-2">
                                            Não
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            {/* Modal de despachar */}
            {
                despacharModalOpen && (
                    <div className="fixed z-10 inset-0 overflow-y-auto">
                        <div className="flex items-center justify-center min-h-screen">
                            <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
                            <div className="modal-container bg-white mx-auto rounded shadow-lg z-50">
                                <div className=" flex flex-col items-center  py-4 px-6">
                                    <p>Deseja realmente despachar o pedido?</p>
                                    <div className="mt-4">
                                        <button onClick={closeDespacharModal} className="bg-red-500 hover:bg-red-600  text-white font-bold py-2 px-4 rounded ">
                                            Não
                                        </button>
                                        <button onClick={handleDespachar} className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded ml-10">
                                            Sim
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            {/* Modal de encerrar */}
            {
                finalizarModalOpen && (
                    <div className="fixed z-10 inset-0 overflow-y-auto">
                        <div className="flex items-center justify-center min-h-screen">
                            <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
                            <div className="modal-container bg-white mx-auto rounded shadow-lg z-50">
                                <div className=" flex flex-col items-center  py-4 px-6">
                                    <p>Deseja realmente finalizar o pedido?</p>
                                    <div className="mt-4">
                                        <button onClick={closeFinalizarModal} className="bg-red-500 hover:bg-red-600  text-white font-bold py-2 px-4 rounded ">
                                            Não
                                        </button>
                                        <button onClick={handleFinalizar} className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded ml-10">
                                            Sim
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

        </div >
    )
}
