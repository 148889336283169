import React, { useState } from 'react';
import { BsTrash, BsPencil } from "react-icons/bs";

function CategoriaAdicional({ index, categoriasAdicionais, setCategoriasAdicionais, listaAdicionais }) {
    const [searchTerm, setSearchTerm] = useState('');
    function normalizeText(text) {
        return text.normalize('NFD').replace(/[\u0300-\u036f]/g, ''); // Remove diacríticos
    }

    const filteredItems = listaAdicionais.filter((item) =>
        normalizeText(item.nome).toLowerCase().includes(normalizeText(searchTerm).toLowerCase())
    );
    const clearSearch = () => setSearchTerm('');
    const [editando, setEditando] = useState({
        nome: true,
        quantidadeMinima: true,
        quantidadeMaxima: true
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const novasCategorias = categoriasAdicionais.map((categoria, i) =>
            i === index ? { ...categoria, [name]: value } : categoria
        );
        setCategoriasAdicionais(novasCategorias);
    };

    const removerCategoria = () => {
        const novasCategorias = categoriasAdicionais.filter((_, i) => i !== index);
        setCategoriasAdicionais(novasCategorias);
    };

    const toggleEdit = (campo) => {
        setEditando((prev) => ({ ...prev, [campo]: !prev[campo] }));
    };

    const handleItemSelect = (itemId) => {
        const novasCategorias = categoriasAdicionais.map((categoria, i) =>
            i === index
                ? {
                    ...categoria,
                    adicionais: categoria.adicionais
                        ? categoria.adicionais.some(item => item.id === itemId)
                            ? categoria.adicionais.filter(item => item.id !== itemId)
                            : [...categoria.adicionais, { id: itemId, nome: listaAdicionais.find(item => item.id === itemId)?.nome || '', limite: 0 }]
                        : [{ id: itemId, nome: listaAdicionais.find(item => item.id === itemId)?.nome || '', limite: 0 }]
                }
                : categoria
        );
        setCategoriasAdicionais(novasCategorias);
    };

    const handleQuantidadeChange = (itemId, novoLimite) => {
        const novasCategorias = categoriasAdicionais.map((categoria, i) =>
            i === index
                ? {
                    ...categoria,
                    adicionais: categoria.adicionais.map(adicional =>
                        adicional.id === itemId
                            ? { ...adicional, limite: parseInt(novoLimite, 10) || 0 }
                            : adicional
                    )
                }
                : categoria
        );
        setCategoriasAdicionais(novasCategorias);
    };

    return (
        <div className='flex flex-col drop-shadow-md bg-white my-3 rounded-md'>
            <div className="p-2 bg-gray-100 rounded-t-md flex flex-row justify-between gap-2">
                <div className="grid grid-cols-3 gap-2 w-full">
                    <div>
                        <label className="block font-semibold mb-1">Nome</label>
                        {editando.nome ? (
                            <input
                                type="text"
                                name="nome"
                                placeholder="Nome"
                                value={categoriasAdicionais[index].nome || ''}
                                onChange={handleInputChange}
                                onBlur={() => toggleEdit('nome')}
                                className="p-2 border rounded-lg w-full"
                            />
                        ) : (
                            <div className='flex'>
                                <span
                                    onClick={() => toggleEdit('nome')}
                                    className="flex justify-between w-full cursor-pointer"
                                >
                                    {categoriasAdicionais[index].nome || 'Nome'}
                                    {categoriasAdicionais[index].nome && (
                                        <BsPencil className="mr-5" />
                                    )}
                                </span>
                            </div>
                        )}
                    </div>
                    <div>
                        <label className="block font-semibold mb-1">Mínimo</label>
                        {editando.quantidadeMinima ? (
                            <input
                                type="number"
                                name="quantidadeMinima"
                                placeholder="Quantidade Mínima"
                                value={categoriasAdicionais[index].quantidadeMinima !== undefined && categoriasAdicionais[index].quantidadeMinima !== null
                                    ? categoriasAdicionais[index].quantidadeMinima
                                    : ''}
                                onChange={handleInputChange}
                                onBlur={() => toggleEdit('quantidadeMinima')}
                                className="p-2 border rounded-lg w-full"
                            />
                        ) : (
                            <div className='flex'>
                                <span
                                    onClick={() => toggleEdit('quantidadeMinima')}
                                    className="flex justify-between w-full cursor-pointer"
                                >
                                    {categoriasAdicionais[index].quantidadeMinima !== undefined && categoriasAdicionais[index].quantidadeMinima !== null
                                        ? categoriasAdicionais[index].quantidadeMinima
                                        : 'Quantidade Mínima'}
                                    {categoriasAdicionais[index].quantidadeMinima !== undefined && categoriasAdicionais[index].quantidadeMinima !== null && (
                                        <BsPencil className="mr-5" />
                                    )}
                                </span>
                            </div>
                        )}
                    </div>
                    <div>
                        <label className="block font-semibold mb-1">Máximo</label>
                        {editando.quantidadeMaxima ? (
                            <input
                                type="number"
                                name="quantidadeMaxima"
                                placeholder="Quantidade Máxima"
                                value={categoriasAdicionais[index].quantidadeMaxima || ''}
                                onChange={handleInputChange}
                                onBlur={() => toggleEdit('quantidadeMaxima')}
                                className="p-2 border rounded-lg w-full"
                            />
                        ) : (
                            <div className='flex'>
                                <span
                                    onClick={() => toggleEdit('quantidadeMaxima')}
                                    className="flex justify-between w-full cursor-pointer"
                                >
                                    {categoriasAdicionais[index].quantidadeMaxima || 'Quantidade Máxima'}
                                    {categoriasAdicionais[index].quantidadeMaxima && (
                                        <BsPencil className="mr-5" />
                                    )}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
                <button
                    className="flex items-center justify-center"
                    onClick={removerCategoria}
                >
                    <BsTrash size={18} />
                </button>
            </div>
            <div className="p-2  max-h-72 min-h-56 overflow-y-auto">
                <div className='mb-2'>
                    <h5 className="font-semibold ">Itens Disponíveis</h5>
                    <span className='text-sm'> ** 0 nos adicionais para não ter limite de escolha</span>
                </div>

                <div>
                    {/* Campo de busca com botão para limpar */}
                    <span className='text-sm'>Filtrar</span>
                    <div className="flex mb-4 items-center ">
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="p-2 border rounded"
                        />
                        {searchTerm && (
                            <button
                                onClick={clearSearch}
                                className="p-2 ml-2 text-gray-500 hover:text-black"
                            >
                                X 
                            </button>
                        )}
                    </div>

                    <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 list-disc">
                        {filteredItems.map((item) => (
                            <div className="flex items-center" key={item.id}>
                                <div
                                    className={`flex w-full items-center p-2 min-h-14 max-h-14 rounded-l cursor-pointer ${categoriasAdicionais[index].adicionais?.some(adicional => adicional.id === item.id)
                                        ? 'bg-blue-100'
                                        : 'bg-white border'
                                        }`}
                                    onClick={() => handleItemSelect(item.id)}
                                >
                                    <input
                                        type="checkbox"
                                        checked={categoriasAdicionais[index].adicionais?.some(adicional => adicional.id === item.id) || false}
                                        onChange={() => handleItemSelect(item.id)}
                                        className="hidden"
                                    />
                                    <span className="flex-grow">{item.nome} - R$ {item.preco.toFixed(2)}</span>
                                </div>

                                {categoriasAdicionais[index].adicionais?.some(adicional => adicional.id === item.id) && (
                                    <input
                                        min="0"
                                        value={categoriasAdicionais[index].adicionais.find(adicional => adicional.id === item.id)?.limite || 0}
                                        onChange={(e) => handleQuantidadeChange(item.id, e.target.value)}
                                        className="border rounded-r text-center w-8 min-h-14 max-h-14"
                                    />
                                )}
                            </div>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default CategoriaAdicional;
