import React, { useState, useEffect } from 'react';
import BackendService from '../integrations/BackendService';
import { HiOutlineChevronUp, HiOutlineChevronDown } from "react-icons/hi";
import {
    CurrencyInput,
    Currencies,
    Locales
} from 'input-currency-react';
import Swal from 'sweetalert2';


const backendService = new BackendService();

export default function Cupom() {
    const [cupons, setCupons] = useState([]);
    const [filteredCupons, setFilteredCupons] = useState([]);
    const [desconto, setDesconto] = useState('');
    const [valorMinimo, setValorMinimo] = useState('');
    const [patrocinador, setPatrocinador] = useState('');
    const [dataValidade, setDataValidade] = useState('');
    const [quantidadeMaximaUsos, setQuantidadeMaximaUsos] = useState('');
    const [tipoDesconto, setTipoDesconto] = useState('VALOR');
    const [quantidadeCupons, setQuantidadeCupons] = useState();

    const [codigoFiltro, setCodigoFiltro] = useState('');
    const [descontoFiltro, setDescontoFiltro] = useState('');
    const [dataValidadeFiltro, setDataValidadeFiltro] = useState('');
    const [tipoDescontoFiltro, setTipoDescontoFiltro] = useState('');
    const [quantidadeMaximaUsosFiltro, setQuantidadeMaximaUsosFiltro] = useState('');
    const [consumidoFiltro, setConsumidoFiltro] = useState('');
    const [patrocinadorFiltro, setPatrocinadorFiltro] = useState('');

    // Estado para controlar a visibilidade da seção de criação de cupons
    const [isExpanded, setIsExpanded] = useState(false);

    function removerAcentos(texto) {
        return texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        filterCupons();
    }, [codigoFiltro, descontoFiltro, dataValidadeFiltro, tipoDescontoFiltro, quantidadeMaximaUsosFiltro, consumidoFiltro, cupons, patrocinadorFiltro]);

    const fetchData = async () => {
        try {
            const response = await backendService.buscarCupons();
            setCupons(response);
            setFilteredCupons(response);
        } catch (error) {
            console.error('Erro ao buscar cupons:', error.message);
        }
    };
    const handleChangeValorMinimo = (campo, valor) => {
        valor = valor.replace(/\s/g, '').replace('R$', '').replace('.', '').replace(',', '.');
        setValorMinimo(valor);
    };
    const handleChangeDesconto = (campo, valor) => {
        valor = valor.replace(/\s/g, '').replace('R$', '').replace('.', '').replace(',', '.');
        setDesconto(valor);
    };

    const filterCupons = () => {
        let tempCupons = cupons;

        if (codigoFiltro) {
            tempCupons = tempCupons.filter(cupom => cupom.codigo.includes(codigoFiltro));
        }
        if (descontoFiltro) {
            tempCupons = tempCupons.filter(cupom => cupom.desconto == descontoFiltro); // Use `==` para comparação numérica
        }
        // if (dataValidadeFiltro) {
        //     console.log(dataValidadeFiltro)
        //     tempCupons = tempCupons.filter(cupom => cupom.dataValidade === dataValidadeFiltro);
        // }
        if (tipoDescontoFiltro) {
            tempCupons = tempCupons.filter(cupom => cupom.tipoDesconto === tipoDescontoFiltro);
        }
        if (quantidadeMaximaUsosFiltro) {
            tempCupons = tempCupons.filter(cupom => cupom.quantidadeMaximaUsos == quantidadeMaximaUsosFiltro); // Use `==` para comparação numérica
        }
        if (consumidoFiltro !== '') {
            tempCupons = tempCupons.filter(cupom => {
                const consumidoTotalmente = cupom.quantidadeUsos >= cupom.quantidadeMaximaUsos;
                return consumidoFiltro === 'consumido' ? consumidoTotalmente : !consumidoTotalmente;
            });
        }
        if (patrocinadorFiltro) {
            tempCupons = tempCupons.filter(cupom =>
                removerAcentos(cupom.patrocinador.toLowerCase()).includes(removerAcentos(patrocinadorFiltro.toLowerCase()))
            );
        }

        setFilteredCupons(tempCupons);
    };

    const handleCreateCoupons = async () => {
        try {
            // Exibe o loading usando SweetAlert2
            Swal.fire({
                title: 'Gerando cupons...',
                text: 'Por favor, aguarde',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                }
            });
    
            const cupomConfig = {
                quantidadeMaximaUsos: quantidadeMaximaUsos,
                desconto: desconto,
                valorMinimo: valorMinimo,
                dataValidade: dataValidade,
                tipoDesconto: tipoDesconto,
                quantidadeCupons: quantidadeCupons,
                patrocinador
            };
    
            // Aguarda a criação dos cupons
            await backendService.criarCupom(cupomConfig);
    
            // Fecha o loading e mostra sucesso
            Swal.fire({
                icon: 'success',
                title: 'Cupons criados com sucesso!',
                text: 'Os cupons foram gerados e estão prontos para uso.'
            }).then(() => {
                // Recarrega a página ao clicar em "OK"
                window.location.reload();
            });
        } catch (error) {
            // Fecha o loading e exibe uma mensagem de erro
            Swal.fire({
                icon: 'error',
                title: 'Erro ao criar cupons',
                text: error.message
            });
            console.error('Erro ao criar cupons:', error.message);
        }
    };

    return (
        <div className="h-screen overflow-y-auto p-8 mx-auto"> {/* Ajuste da altura e rolagem vertical */}
            <h1 className="text-3xl font-bold mb-6">Administração de Cupons</h1>

            {/* Seção de Criação de Cupons */}
            <div className="bg-white shadow-md rounded-lg mb-6">
                <button
                    onClick={() => setIsExpanded(!isExpanded)}
                    className="w-full text-left  px-6 py-4 rounded-t-lg flex items-center justify-between focus:outline-none"
                >
                    <h2 className="text-2xl font-semibold">Criar Cupons</h2>
                    {isExpanded ? (
                        <HiOutlineChevronUp className="h-6 w-6 text-gray-500" />
                    ) : (
                        <HiOutlineChevronDown className="h-6 w-6 text-gray-500" />
                    )}
                </button>
                {isExpanded && (
                    <div className="p-6">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">

                            <div>
                                <label htmlFor="quantidadeMaximaUsos" className="block text-sm font-medium text-gray-700">Quantidade Máxima de Usos</label>
                                <input
                                    id="quantidadeMaximaUsos"
                                    type="number"
                                    value={quantidadeMaximaUsos}
                                    onChange={(e) => setQuantidadeMaximaUsos(e.target.value)}
                                    placeholder="Quantidade Máxima de Usos"
                                    className="mt-1 p-2 border border-gray-300 rounded w-full"
                                />
                            </div>
                            <div>
                                <label htmlFor="tipoDesconto" className="block text-sm font-medium text-gray-700">Tipo de Desconto</label>
                                <select
                                    id="tipoDesconto"
                                    value={tipoDesconto}
                                    onChange={(e) => setTipoDesconto(e.target.value)}
                                    className="mt-1 p-2 border border-gray-300 rounded w-full"
                                >
                                    <option value="VALOR">Valor</option>
                                    <option value="PORCENTAGEM">Porcentagem</option>
                                </select>
                            </div>
                            <div>
                                <label htmlFor="dataValidade" className="block text-sm font-medium text-gray-700">Data de Validade</label>
                                <input
                                    id="dataValidade"
                                    type="date"
                                    value={dataValidade}
                                    onChange={(e) => setDataValidade(e.target.value)}
                                    className="mt-1 p-2 border border-gray-300 rounded w-full"
                                />
                            </div>

                            <div>
                                <label htmlFor="desconto" className="block text-sm font-medium text-gray-700">Desconto</label>
                                {tipoDesconto === "VALOR" ? (
                                    <CurrencyInput
                                        options={{
                                            precision: 2,
                                            style: "currency",
                                            locale: Locales["Portuguese (Brazil)"], // Format Type
                                            i18nCurrency: Currencies["Brazilian Real"] // Symbol
                                        }}
                                        placeholder="Desconto"
                                        value={desconto}
                                        onChangeEvent={handleChangeDesconto}
                                        className="mt-1 p-2 border border-gray-300 rounded w-full"
                                    />
                                ) : (
                                    <input
                                        id="desconto"
                                        type="number"
                                        step="0.01"
                                        value={desconto}
                                        onChange={(e) => setDesconto(e.target.value)}
                                        placeholder="Desconto"
                                        className="mt-1 p-2 border border-gray-300 rounded w-full"
                                    />
                                )}
                            </div>
                            <div>
    <label htmlFor="quantidadeCupons" className="block text-sm font-medium text-gray-700">
        Quantidade de Cupons (máximo 10)
    </label>
    <input
        id="quantidadeCupons"
        type="number"
        value={quantidadeCupons}
        onChange={(e) => {
            const value = e.target.value;

            // Permite que o campo seja limpo e lida com o valor vazio corretamente
            if (value === '') {
                setQuantidadeCupons('');
                return;
            }

            // Convertendo o valor para número e aplicando validação
            const intValue = parseInt(value, 10);
            if (!isNaN(intValue) && intValue <= 10) {
                setQuantidadeCupons(intValue);
            }
        }}
        placeholder="Quantidade de Cupons"
        className="mt-1 p-2 border border-gray-300 rounded w-full"
        max="10"
    />
</div>

                            <div>
                                <label htmlFor="valor mínimo" className="block text-sm font-medium text-gray-700">Valor mínimo</label>
                                <CurrencyInput options={{
                                    precision: 2,
                                    style: "currency",
                                    locale: Locales["Portuguese (Brazil)"], // Format Type
                                    i18nCurrency: Currencies["Brazilian Real"] // Symbol
                                }}
                                    placeholder="Preço" value={valorMinimo} onChangeEvent={handleChangeValorMinimo} className="mt-1 p-2 border border-gray-300 rounded w-full" />
                            </div>
                            <div>
                                <label htmlFor="patrocinador" className="block text-sm font-medium text-gray-700">Patrocinador</label>
                                <input
                                    id="patrocinador"
                                    type="text"
                                    value={patrocinador}
                                    onChange={(e) => setPatrocinador(e.target.value)}
                                    placeholder="Patrocinador"
                                    className="mt-1 p-2 border border-gray-300 rounded w-full"
                                />
                            </div>
                        </div>
                        <button
                            onClick={handleCreateCoupons}
                            className="mt-4 bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
                        >
                            Criar Cupons
                        </button>
                    </div>
                )}
            </div>

            {/* Seção de Cupons Existentes */}
            <div className="bg-white shadow-md rounded-lg p-6 mb-20">
                <h2 className="text-2xl font-semibold mb-4">Cupons Existentes</h2>

                {/* Filtros */}
                <div className="bg-gray-50 p-4 rounded-md mb-6">
                    <h3 className="text-xl font-semibold mb-4">Filtros</h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <label htmlFor="codigoFiltro" className="block text-sm font-medium text-gray-700">Código do Cupom</label>
                            <input
                                id="codigoFiltro"
                                type="text"
                                value={codigoFiltro}
                                onChange={(e) => setCodigoFiltro(e.target.value)}
                                placeholder="Código do cupom"
                                className="mt-1 p-2 border border-gray-300 rounded w-full"
                            />
                        </div>
                        <div>
                            <label htmlFor="descontoFiltro" className="block text-sm font-medium text-gray-700">Desconto</label>
                            <input
                                id="descontoFiltro"
                                type="number"
                                step="0.01"
                                value={descontoFiltro}
                                onChange={(e) => setDescontoFiltro(e.target.value)}
                                placeholder="Desconto"
                                className="mt-1 p-2 border border-gray-300 rounded w-full"
                            />
                        </div>
                        {/* <div>
                            <label htmlFor="dataValidadeFiltro" className="block text-sm font-medium text-gray-700">Data de Validade</label>
                            <input
                                id="dataValidadeFiltro"
                                type="date"
                                value={dataValidadeFiltro}
                                onChange={(e) => setDataValidadeFiltro(e.target.value)}
                                className="mt-1 p-2 border border-gray-300 rounded w-full"
                            />
                        </div> */}
                        <div>
                            <label htmlFor="tipoDescontoFiltro" className="block text-sm font-medium text-gray-700">Tipo de Desconto</label>
                            <select
                                id="tipoDescontoFiltro"
                                value={tipoDescontoFiltro}
                                onChange={(e) => setTipoDescontoFiltro(e.target.value)}
                                className="mt-1 p-2 border border-gray-300 rounded w-full"
                            >
                                <option value="">Todos</option>
                                <option value="VALOR">Valor</option>
                                <option value="PORCENTAGEM">Porcentagem</option>
                            </select>
                        </div>
                        <div>
                            <label htmlFor="quantidadeMaximaUsosFiltro" className="block text-sm font-medium text-gray-700">Quantidade Máxima de Usos</label>
                            <input
                                id="quantidadeMaximaUsosFiltro"
                                type="number"
                                value={quantidadeMaximaUsosFiltro}
                                onChange={(e) => setQuantidadeMaximaUsosFiltro(e.target.value)}
                                placeholder="Quantidade Máxima de Usos"
                                className="mt-1 p-2 border border-gray-300 rounded w-full"
                            />
                        </div>
                        <div>
                            <label htmlFor="consumidoFiltro" className="block text-sm font-medium text-gray-700">Consumido</label>
                            <select
                                id="consumidoFiltro"
                                value={consumidoFiltro}
                                onChange={(e) => setConsumidoFiltro(e.target.value)}
                                className="mt-1 p-2 border border-gray-300 rounded w-full"
                            >
                                <option value="">Todos</option>
                                <option value="consumido">Consumido Totalmente</option>
                                <option value="naoConsumido">Não Consumido Totalmente</option>
                            </select>
                        </div>
                        <div>
                            <label htmlFor="patrocinador" className="block text-sm font-medium text-gray-700">Patrocinador</label>
                            <input
                                id="patrocinador"
                                type="text"
                                value={patrocinadorFiltro}
                                onChange={(e) => setPatrocinadorFiltro(e.target.value)}
                                placeholder="Patrocinador"
                                className="mt-1 p-2 border border-gray-300 rounded w-full"
                            />
                        </div>
                    </div>
                </div>

                {/* Tabela com rolagem */}
                <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Código</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Patrocinador</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Valor mínimo</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Desconto</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tipo</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Validade</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Usos</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {filteredCupons.map(cupom => (
                                <tr key={cupom.id}>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{cupom.codigo}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{cupom.patrocinador}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">R$ {cupom.valorMinimo.toFixed(2)}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"> {cupom.tipoDesconto === 'VALOR' ? `R$ ${cupom.desconto.toFixed(2)}` : `${cupom.desconto}%`}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{cupom.tipoDesconto}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{cupom.dataValidade}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{cupom.quantidadeUsos}/{cupom.quantidadeMaximaUsos}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}